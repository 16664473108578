/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Rnd } from "react-rnd";
import { Checkbox } from "antd";
import "./App.css";

import staticProImage from "../src/image/FeedFrame.png";
import staticPostImage from "../src/image/ProfileFrame.png";

import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

const App = () => {
  // const [staticProImage, setStaticProImage] = useState(null);
  const download = { width: 1080, height: 1080 };
  const [uploadProImageRealSize, setUploadProImageRealSize] = useState({
    width: 0,
    height: 0,
  });

  const [proSign, setProSign] = useState(false);
  const [PostSign, setPostSign] = useState(false);

  const [uploadPostImageRealSize, setUploadPostImageRealSize] = useState({
    width: 0,
    height: 0,
  });
  const [uploadedProImage, setUploadedProImage] = useState(null);
  // const [staticPostImage, setStaticPostImage] = useState(null);
  const [uploadedPostImage, setUploadedPostImage] = useState(null);
  const [checked, setChecked] = useState(true);
  const [postChecked, setPostChecked] = useState(true);
  const [proSize, setProSize] = useState({ width: 300, height: 300 });
  const [position, setPosition] = useState({ x: 0, y: 30 });
  const [scale, setScale] = useState({ x: 1, y: 1 });
  const [proPposition, setProPosition] = useState({ x: 0, y: 30 });
  const [proScale, setProScale] = useState({ x: 1, y: 1 });

  const onChange = (e) => {
    console.log("checked = ", e.target.checked);
    setChecked(e.target.checked);
  };
  const onPostChange = (e) => {
    console.log("checked = ", e.target.checked);
    setPostChecked(e.target.checked);
  };
  // useEffect(() => {
  //   getImageFromUrl(proImage)
  //     .then((imageObject) => {
  //       setStaticProImage(imageObject);
  //       // Use the image object here
  //     })
  //     .catch((error) => {
  //       console.error("Error loading image:", error);
  //     });

  //   getImageFromUrl(PostImage)
  //     .then((imageObject) => {
  //       setStaticPostImage(imageObject);
  //       // Use the image object here
  //     })
  //     .catch((error) => {
  //       console.error("Error loading image:", error);
  //     });
  // }, []);

  const handleuploadedProImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          setUploadProImageRealSize({
            width: img.naturalWidth,
            height: img.naturalHeight,
          });

          console.log(">>>>>>>>>>>>>>", img.naturalWidth > img.naturalHeight);
          setProSign(img.naturalWidth > img.naturalHeight);
        };

        img.src = reader.result;
        setUploadedProImage(reader.result);
        setScale({ x: 1, y: 1 });
        setPosition({ x: 0, y: 0 });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleuploadedPostImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          setUploadPostImageRealSize({
            width: img.naturalWidth,
            height: img.naturalHeight,
          });
          setPostSign(img.naturalWidth > img.naturalHeight);
        };
        img.src = reader.result;
        setUploadedPostImage(reader.result);
        setProScale({ x: 1, y: 1 });
        setProPosition({ x: 0, y: 0 });
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleResize = (event, direction, ref, delta, position) => {
  //   const newScaleX = parseFloat(ref.style.width) / proSize.width;
  //   const newScaleY = parseFloat(ref.style.height) / proSize.height;

  //   setPosition({ x: position.x, y: position.y });
  //   setScale({ x: newScaleX, y: newScaleY });
  // };

  const handlePostResize = (event, direction, ref, delta, position) => {
    const newScaleX = parseFloat(ref.style.width) / proSize.width;
    const newScaleY = parseFloat(ref.style.height) / proSize.height;

    setProPosition({ x: position.x, y: position.y });
    setProScale({ x: newScaleX, y: newScaleY });
  };

  const handleDragStop = (event, { x, y }) => {
    setPosition({ x, y });
  };

  const handleProDragStop = (event, { x, y }) => {
    setProPosition({ x, y });
  };

  const handleProDownload = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const staticImageObj = new Image();
    const uploadedProImageObj = new Image();
    uploadedProImageObj.crossOrigin = "anonymous";

    canvas.width = download.width;
    canvas.height = download.height;

    context.clearRect(0, 0, canvas.width, canvas.height);

    uploadedProImageObj.onload = () => {
      const newWidth =
        download.width *
        scale.x *
        (proSign === true
          ? 1
          : uploadProImageRealSize.width / uploadProImageRealSize.height);
      const newHeight =
        download.height *
        scale.x *
        (proSign === false
          ? 1
          : uploadProImageRealSize.height / uploadProImageRealSize.width);
      context.drawImage(
        uploadedProImageObj,
        position.x * (download.width / proSize.width),
        position.y * (download.height / proSize.height),
        newWidth,
        newHeight
      );

      staticImageObj.onload = () => {
        const backgroundColor = "#ffffff";

        // Draw the static image on the canvas
        context.drawImage(staticImageObj, 0, 0, canvas.width, canvas.height);

        // Get the image data from the canvas
        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );

        // Get the color values for the background color
        const r = parseInt(backgroundColor.substring(1, 3), 16);
        const g = parseInt(backgroundColor.substring(3, 5), 16);
        const b = parseInt(backgroundColor.substring(5, 7), 16);

        // Modify the alpha channel of undefined pixels to make them yellow
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          if (data[i + 3] === 0) {
            data[i] = r;
            data[i + 1] = g;
            data[i + 2] = b;
            data[i + 3] = 255;
          }
        }

        // Put the modified image data back on the canvas
        context.putImageData(imageData, 0, 0);

        // Perform any necessary post-processing or actions here

        // Save or display the canvas image
        const downloadLink = document.createElement("a");
        const url = canvas.toDataURL();
        downloadLink.href = url;
        downloadLink.download = "ProImage.png";
        downloadLink.click();
      };
      staticImageObj.src = staticProImage;
    };
    uploadedProImageObj.src = uploadedProImage;
  };

  const handlePostDownload = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const staticImageObj = new Image();
    const uploadedProImageObj = new Image();
    uploadedProImageObj.crossOrigin = "anonymous";

    canvas.width = download.width;
    canvas.height = download.height;

    context.clearRect(0, 0, canvas.width, canvas.height);

    uploadedProImageObj.onload = () => {
      const newWidth =
        download.width *
        proScale.x *
        (PostSign === true
          ? 1
          : uploadPostImageRealSize.width / uploadPostImageRealSize.height);
      const newHeight =
        download.height *
        proScale.x *
        (PostSign === false
          ? 1
          : uploadPostImageRealSize.height / uploadPostImageRealSize.width);

      console.log(
        "????????????????",
        newHeight,
        PostSign === true
          ? 1
          : uploadPostImageRealSize.width / uploadPostImageRealSize.height
      );
      context.drawImage(
        uploadedProImageObj,
        proPposition.x * (download.width / proSize.width),
        proPposition.y * (download.height / proSize.height),
        newWidth,
        newHeight
      );

      staticImageObj.onload = () => {
        const backgroundColor = "#ffffff";

        // Draw the static image on the canvas
        context.drawImage(staticImageObj, 0, 0, canvas.width, canvas.height);

        // Get the image data from the canvas
        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );

        // Get the color values for the background color
        const r = parseInt(backgroundColor.substring(1, 3), 16);
        const g = parseInt(backgroundColor.substring(3, 5), 16);
        const b = parseInt(backgroundColor.substring(5, 7), 16);

        // Modify the alpha channel of undefined pixels to make them yellow
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          if (data[i + 3] === 0) {
            data[i] = r;
            data[i + 1] = g;
            data[i + 2] = b;
            data[i + 3] = 255;
          }
        }

        // Put the modified image data back on the canvas
        context.putImageData(imageData, 0, 0);

        // Perform any necessary post-processing or actions here

        // Save or display the canvas image
        const downloadLink = document.createElement("a");
        const url = canvas.toDataURL();
        downloadLink.href = url;
        downloadLink.download = "PostImage.png";
        downloadLink.click();
      };
      staticImageObj.src = staticPostImage;
    };
    uploadedProImageObj.src = uploadedPostImage;
  };

  const handleProScaleChangeX = (event) => {
    const newScale = parseFloat(event.target.value);
    setScale({ x: newScale, y: scale.x });
  };
  const handleProScaleChangeY = (event) => {
    const newScale = parseFloat(event.target.value);
    setScale({ x: scale.x, y: newScale });
  };

  const handlePostScaleChangeX = (event) => {
    const newScale = parseFloat(event.target.value);
    setProScale({ x: newScale, y: proScale.x });
  };

  return (
    <div className=" flex justify-center flex-col items-center">
      <div className="w-[100%]">
        <img
          style={{ width: "100%" }}
          src="https://cdn.concertrx.com/metaImage.png"
        ></img>
      </div>
      <div className=" mt-[20px]">
        <p
          className=" font-bold text-[24px] text-center text-[#F74F0B]"
          style={{ fontFamily: "Work Sans" }}
        >
          Profile Photo Frame Creator
        </p>
        <p
          className=" text-[16px] text-center text-[#636363] p-[10px]"
          style={{ fontFamily: "Work Sans" }}
        >
          Use this tool to insert your own photo into the campaign frame to
          create a PROFILE picture.
        </p>
      </div>

      <div className=" relative inline-block w-[300px] h-[300px] overflow-hidden">
        <img
          src={staticProImage}
          alt="Static"
          style={{
            width: "300px",
            height: "300px",
            zIndex: checked ? -1 : 1,
          }}
        />
        {uploadedProImage && (
          <Rnd
            style={{
              position: "absolute",
              zIndex: checked ? 1 : -1,
              border: checked ? "1px solid black" : "",
            }}
            size={{
              width:
                proSize.width *
                scale.x *
                (proSign === true
                  ? 1
                  : uploadProImageRealSize.width /
                    uploadProImageRealSize.height),
              height:
                proSize.height *
                scale.x *
                (proSign === false
                  ? 1
                  : uploadProImageRealSize.height /
                    uploadProImageRealSize.width),
            }}
            position={{ x: position.x, y: position.y }}
            onDragStop={handleDragStop}
            enableResizing={false}
          >
            <img
              src={uploadedProImage}
              alt="Uploaded"
              style={{
                width: "100%",
                height: "100%",
                opacity: checked ? "60%" : "100%",
              }}
            />
          </Rnd>
        )}
      </div>
      <div>
        <Checkbox checked={checked} onChange={onChange}>
          <p style={{ fontFamily: "sans-serif" }}>Edit</p>
        </Checkbox>
      </div>
      <div className=" flex justify-center items-center">
        <p>Zoom</p>
        <input
          disabled={!checked}
          type="range"
          id="scaleRange"
          min="0.1"
          max="3"
          step="0.01"
          value={scale.x}
          onChange={handleProScaleChangeX}
        />
      </div>
      <div className=" mt-[8px] flex justify-center flex-row gap-[8px]">
        <div>
          <label
            className=" bg-[#0086D5] p-[8px] w-[120px]   rounded-[6px] text-white"
            style={{ fontFamily: "Poppins" }}
          >
            <span
              class="file-upload-text"
              className=" p-[4px] text-[18px] text-center"
            >
              <UploadOutlined />
              Upload
            </span>
            <input
              type="file"
              id="uploadedProImage"
              accept="image/*"
              onChange={handleuploadedProImageChange}
            />
          </label>
        </div>

        <div

        // className=" bg-[#F74F0B] w-[200px] h-[40px] p-[8px] rounded-[8px]"
        >
          {/* <p
            style={{ fontFamily: "poppins" }}
            className=" text-white text-center leading-trim-both text-[18px] font-normal"
          >
            DOWNLOAD CAMPAIGN ASSETS
          </p>
           */}
          <label
            onClick={handleProDownload}
            className=" bg-[#F74F0B] p-[8px] w-[120px]   rounded-[6px] text-white"
            style={{ fontFamily: "Poppins" }}
          >
            <span
              class="file-upload-text"
              className=" p-[4px] text-[18px] text-center"
            >
              <DownloadOutlined />
              Download
            </span>
          </label>
        </div>
      </div>
      <div className=" mt-[8px]">
        <p
          className=" font-bold text-[24px] text-center text-[#F74F0B]"
          style={{ fontFamily: "Work Sans" }}
        >
          Post Picture Overlay Creator
        </p>
        <p
          className=" text-[16px] text-center text-[#636363] p-[10px]"
          style={{ fontFamily: "Work Sans" }}
        >
          Use this tool to insert your own photo into the campaign frame to
          create a POST.
        </p>
      </div>

      <div className=" w-[300px]  mt-[20px]  items-center">
        <div className=" relative inline-block h-[300px]  w-[300px] overflow-hidden">
          <img
            src={staticPostImage}
            alt="Static"
            style={{
              width: "100%",
              height: "100%",
              zIndex: postChecked ? -1 : 1,
            }}
          />
          {uploadedPostImage && (
            <Rnd
              style={{
                position: "absolute",
                zIndex: postChecked ? 1 : -1,
                border: postChecked ? "1px solid grey" : "",
              }}
              size={{
                width:
                  proSize.width *
                  proScale.x *
                  (PostSign === true
                    ? 1
                    : uploadPostImageRealSize.width /
                      uploadPostImageRealSize.height),
                height:
                  proSize.height *
                  proScale.x *
                  (PostSign === false
                    ? 1
                    : uploadPostImageRealSize.height /
                      uploadPostImageRealSize.width),
              }}
              position={{ x: proPposition.x, y: proPposition.y }}
              onDragStop={handleProDragStop}
              onResize={handlePostResize}
              enableResizing={false}
            >
              <img
                src={uploadedPostImage}
                alt="Uploaded"
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: postChecked ? "60%" : "100%",
                }}
              />
            </Rnd>
          )}
        </div>
      </div>
      <div>
        <Checkbox checked={postChecked} onChange={onPostChange}>
          <p style={{ fontFamily: "sans-serif" }}>Edit</p>
        </Checkbox>
      </div>
      <div className=" flex justify-center items-center gap-[4px]">
        <p>Zoom</p>
        <input
          disabled={!postChecked}
          type="range"
          id="scaleRange"
          min="0.1"
          max="3"
          step="0.01"
          value={proScale.x}
          onChange={handlePostScaleChangeX}
        />
      </div>
      <div className=" mt-[8px] flex justify-center flex-row gap-[8px] mb-[30px]">
        <div>
          <label
            className=" bg-[#0086D5] p-[8px] w-[120px]   rounded-[6px] text-white"
            style={{ fontFamily: "Poppins" }}
          >
            <span
              class="file-upload-text"
              className=" p-[4px] text-[18px] text-center"
            >
              <UploadOutlined />
              Upload
            </span>
            <input
              type="file"
              id="uploadedProImage"
              accept="image/*"
              onChange={handleuploadedPostImageChange}
            />
          </label>
        </div>

        <div onClick={handlePostDownload}>
          <label
            className=" bg-[#F74F0B] p-[8px] w-[120px]   rounded-[6px] text-white"
            style={{ fontFamily: "Poppins" }}
          >
            <span
              class="file-upload-text"
              className=" p-[4px] text-[18px] text-center"
            >
              <DownloadOutlined />
              Download
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default App;
